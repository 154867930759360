import Privilege from "./Privilege.vue"
import PrivilegeCreate from "./Create.vue"
import PrivilegeShow from "./Show.vue"

export default [
    {
        path: "/privilege",
        component: Privilege,
        name: "Privilege"
    },
    {
        path: "/privilege/create",
        component: PrivilegeCreate,
        name: "PrivilegeCreate"
    },
    {
        path: "/privilege/:id",
        component: PrivilegeShow,
        name: "PrivilegeShow"
    },
]