<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'PrivilegeCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="privilage"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <!-- <td>
                      <button
                        class="d-inline badge badge-info badge-sm mr-1"
                        v-for="children in row.item.children"
                        :key="children.id"
                        @click="deleteMenu(children.id)"
                      >
                        {{ children.menu_name }}
                      </button>
                    </td> -->
                    <td>
                      <router-link
                        :to="{
                          name: 'PrivilegeShow',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-primary"
                      >
                        Show
                      </router-link>
                      <!-- <button
                        @click="deleteMenu(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button> -->
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "Privilege",
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      name: "Privilege",
      privilage: [],
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(this.$store.state.api + "privilege", {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          this.privilage = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    deleteMenu(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "privilege/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(() => {
              this.privilage = this.privilage.filter((v) => {
                return v.id != id;
              });
              this.getData();
            })
            .catch((err) => console.log(err));
          swal("Poof! Your privilage file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your privilage file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Role",
          value: "name",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>
