<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Privilage</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <h2 class="section-title">Forms</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="privilageInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>User Role</label>
                  <select
                    class="custom-select"
                    v-model="form.role_id"
                    @change="getUserMenu"
                  >
                    <option selected="" value="">Open this select menu</option>
                    <option v-for="r in role" :key="r.id" :value="r.id">{{
                      r.name
                    }}</option>
                  </select>
                  <small class="text-danger" v-if="errors.role_id">{{
                    errors.role_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Menu</label>
                  <select
                    class="custom-select select2"
                    multiple
                    v-model="form.menu_id"
                  >
                    <option selected="" value="">Open this select menu</option>
                    <option v-for="m in menu" :key="m.id" :value="m.id">{{
                      m.name
                    }}</option>
                  </select>
                  <small class="text-danger" v-if="errors.menu_id">{{
                    errors.menu_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit">
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { apiGet } from "../../../services/api";

export default {
  name: "FormPrivilege",
  data() {
    return {
      name: "Form Privilege",
      form: {
        role_id: "",
        menu_id: [],
      },
      errors: {},
      role: [],
      menu: [],
      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    axios
      .get(this.$store.state.api + "role", {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => (this.role = res.data.data))
      .catch((err) => console.log(err));
    // axios
    //   .get(this.$store.state.api + "menu", {
    //     headers: { Authorization: this.$store.state.token },
    //   })
    //   .then((res) => {
    //     this.menu = res.data.data;
    //   })
    //   .catch((err) => console.log(err));
  },
  methods: {
    getUserMenu() {
      apiGet("privilege/get-menu/" + this.form.role_id)
        .then((res) => {
          this.menu = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    privilageInsert() {
      let formData = new FormData();
      formData.set("role_id", this.form.role_id);
      for (var i = 0; i < this.form.menu_id.length; i++) {
        formData.append("menu_id[]", this.form.menu_id[i]);
      }
      axios
        .post(this.$store.state.api + "privilege/store", formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your privilege has been saved!");
          this.$router.push({ name: "Privilege" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          console.log(error);
        });
    },
  },
};
</script>
<style>
.btn-primary {
  color: #fff;
}
</style>
